ion-grid.dashboard {
    ion-col {
        --ion-grid-column-padding: 3px;
        min-width: 250px;
    }

    a {
        text-decoration: none;
    }

    a.tile {
        height: 100%;
        margin: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        box-shadow: 0px 1px 4px 0px #000;
        color: #000;
        background: var(--ion-color-light);


        img {
            border-radius: 50%;
            box-shadow: 0px 1px 4px 0px #000;
        }

        .place-holder {
            height: 60px;
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            box-shadow: 0px 1px 4px 0px #000;
            background: rgb(23,162,184,0.7);
            color: white;
            padding: 20px;

            svg {
                font-size: 40px;
                color: white;
                padding: 20px;
            }
        }
        h1{
            font-size: 45px;
        }

        &.tertiary,
        &.primary {
            color: white;
        }

        p {
            margin: 10px 0 0;
            font-weight: bold;
            line-height: 1.3;
            letter-spacing: 0.2em;
            word-break: break-all;
            text-align: center;
        }

        @media only screen and (min-width: 1200px) {
            padding: 30px;
        }
    }
}