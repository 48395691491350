.svg-inline--fa {
    font-size: 20px;
    color: #757575;
}

ion-app ion-router-outlet {
    display: flex;
    flex-direction: column;
}

ion-tab-bar {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#eeeeee+0,cccccc+100;Gren+3D */
    background: #eeeeee;
    /* Old browsers */
    background: -moz-linear-gradient(top, #eeeeee 0%, #cccccc 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #eeeeee 0%, #cccccc 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #eeeeee 0%, #cccccc 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#cccccc', GradientType=0);
    /* IE6-9 */

}

ion-toolbar {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#eeeeee+0,cccccc+100;Gren+3D */
    --background: #eeeeee;
    /* Old browsers */
    --background: -moz-linear-gradient(top, #eeeeee 0%, #cccccc 100%);
    /* FF3.6-15 */
    --background: -webkit-linear-gradient(top, #eeeeee 0%, #cccccc 100%);
    /* Chrome10-25,Safari5.1-6 */
    --background: linear-gradient(to bottom, #eeeeee 0%, #cccccc 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#cccccc', GradientType=0);
    /* IE6-9 */

}

ion-content {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#eeeeee+0,cccccc+100;Gren+3D */
    --background: #eeeeee;
    /* Old browsers */
    --background: -moz-linear-gradient(top, #eeeeee 0%, #cccccc 100%);
    /* FF3.6-15 */
    --background: -webkit-linear-gradient(top, #eeeeee 0%, #cccccc 100%);
    /* Chrome10-25,Safari5.1-6 */
    --background: linear-gradient(to bottom, #eeeeee 0%, #cccccc 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#cccccc', GradientType=0);
    /* IE6-9 */
}

ion-tab-button {
    background: transparent;

    &.tab-selected {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#cccccc+0,eeeeee+100 */
        background: #cccccc;
        /* Old browsers */
        background: -moz-linear-gradient(top, #cccccc 0%, #eeeeee 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #cccccc 0%, #eeeeee 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #cccccc 0%, #eeeeee 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cccccc', endColorstr='#eeeeee', GradientType=0);
        /* IE6-9 */

    }
}

ion-label.label-floating {
    align-items: center;
    display: flex !important;

    ion-icon {
        padding-right: 10px;
        font-size: 20px;
    }
}

.text-center {
    text-align: center;
}

.container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    strong {
        font-size: 20px;
        line-height: 26px;
    }

    p {
        font-size: 16px;
        line-height: 22px;
        color: #8c8c8c;
        margin: 0;
    }

    a {
        text-decoration: none;
    }
}

.list-item {
    &:hover {
        background-color: var(--ion-color-light-shade);
        box-shadow: none;
    }

    margin: 10px 5px;
    background-color: var(--ion-color-primary-contrast);
    padding: 10px;
    display: flex;
    align-items: center;
    text-decoration: none;
    box-shadow: 0px 1px 4px 0px #000;
    border-radius: 5px;

    img {
        // padding: 10px 0;
        margin-right: 20px;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        box-shadow: 0px 1px 4px 0px #000;
    }

    .place-holder {
        display: block;
        padding: 10px;
        margin-right: 20px;
        border-radius: 50%;
        box-shadow: 0px 1px 4px 0px #000;

        svg {
            width: 60px;
        }
    }

    h4 {
        line-height: 1.3;
        overflow: hidden;
    }
}

.section-content {
    overflow: auto;
    padding: 20px;
}

#post-body {
    background-color: white;
}

dr-post {
    color: var(--ion-color-tertiary-shade);
    font-weight: bold;
    border-bottom: 2px solid;
}

.table-responsive {
    overflow: auto;

    &.dashboard {
        max-height: 300px;
    }

    table {
        width: 100%;

        thead {
            background-color: var(--ion-color-medium);
        }

        tbody tr {
            background-color: #fff;
            transition: all .125s ease-in-out;

            &:nth-child(2n-1) {
                background-color: #f5f5f5;
            }

            &:hover {
                background-color: rgba(208, 208, 208, 0.3);
            }
        }

        td,
        th {
            padding: 0.75em 1.5em;
            font-weight: 600;
            color: #555;
        }

        th {
            font-weight: bold;
            color: #fff;
            white-space: nowrap;
        }
    }
}

input.dummy {
    position: absolute;
    top: -100px;
}

.contact {
    margin: 5px 0;

    h5 {
        margin: 0;
        font-weight: 600;
    }

    ion-text {
        line-height: 1.5;
    }
}

.crud-items {
    display: flex;
    flex-wrap: wrap;
    margin: 20px;

    @media only screen and (min-width: 768px) {
        margin: 1.5% 0 1.5% 1.5%;
    }

    @media only screen and (min-width: 1024px) {
        margin: 1% 0 1% 1%;
    }

    @media only screen and (min-width: 1440px) {
        margin: 1% 0 1% 1%;
    }

    ion-item {
        width: 100%;

        // @media only screen and (min-width: 426px) {
        //     max-width: 320px;
        // }
        @media only screen and (min-width: 768px) {
            max-width: 48.5%;
            margin-right: 1.5%;
        }

        @media only screen and (min-width: 1024px) {
            max-width: 32%;
            margin-right: 1%;
        }

        @media only screen and (min-width: 1440px) {
            max-width: 24%;
            margin-right: 1%;
        }
    }
}

ion-modal {
    &.full-height {
        --height: 100vh
    }

    &.half-width {
        @media only screen and (min-width: 1024px) {
            --width: 50vw
        }
    }

    &.align-end {
        justify-content: end
    }
}

.contact {
    ion-text{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    h5 {
        margin: 0;
    }
    .right{
        text-align: right;
    }
}

.notification {

    ion-badge {
        position: absolute;
        top: 0px;
        left: 16px;
        font-size: 12px;
        padding: 2px;
    }
}

ion-badge.filter {
    position: absolute;
    top: 4px;
    right: 10px;
}