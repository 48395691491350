.details {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;

  .item {
    padding: 20px 10px;
    width: 32%;
    height: 110px;
    text-align: center;
    background: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);

    * {
      margin: 0;
    }

    h1 {
      font-size: 1.5rem;
    }
  }
}
.invoice-edit{
  ion-row{
    width: 100%;
  }
  ion-input{
    background: #eee;
    input{
      padding: 10px !important;
    }
    &.client{
      background: #cecece;
    }
    &.driver{
      background: var(--ion-color-light-shade);
    }
  }
}