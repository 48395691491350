.trips-filter {
    margin: 0 0 10px;
}

.trips {
    cursor: pointer;

    ion-item {
        >div {
            padding: 10px 0;
        }

        .head {
            width: 90px;
        }

        &:hover {
            --background: #B8D8C7;

            .price {
                color: black;
            }
        }
    }

    h4,
    h5 {
        margin: 0;
    }
    h6{
        margin: 0 0 5px
    }


    h4,
    h5,
    .price {
        font-weight: 600;
    }

    ion-text {
        line-height: 1.5;
    }
}